<template>
  <v-navigation-drawer app dark permanent>
    <v-toolbar @click="home" flat>
      <v-list>
        <v-list-item>
          <v-list-item-title class="title">Simple Time Cards</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-toolbar>

    <v-list class="pt-2" dense>

      <v-list-item to="/admin/employees">
        <v-list-item-action>
          <v-icon>mdi-account-multiple-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="white--text">Employees</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/admin/payroll/current">
        <v-list-item-action>
          <v-icon class="white--text">mdi-clipboard-check</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="white--text">Current Payroll Period</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/admin/payroll/previous">
        <v-list-item-action>
          <v-icon class="white--text">mdi-format-list-bulleted</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="white--text">Previous Payroll Period</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!--
            <v-list-item></v-list-item>
            <v-divider></v-divider>
      -->

      <v-list-item to="/admin/payroll/archives">
        <v-list-item-action>
          <v-icon class="white--text">mdi-script-text</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="white--text">Archives</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <div v-if="is_system">
        <v-list-item></v-list-item>
        <v-divider></v-divider>

        <v-list-item to="/admin/sessions">
          <v-list-item-action>
            <v-icon>mdi-web</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text">Sessions</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/admin/payroll/all">
          <v-list-item-action>
            <v-icon class="white--text">mdi-script-text</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text">All Payrolls</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/admin/users">
          <v-list-item-action>
            <v-icon>mdi-account-supervisor</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text">Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/admin/tablets">
          <v-list-item-action>
            <v-icon>mdi-tablet-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text">Tablets</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </div>

      <v-list-item></v-list-item>
      <v-divider></v-divider>

      <v-list-item @click="logout">
        <v-list-item-action>
          <v-icon class="white--text">mdi-lock-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="white--text">Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="home">
        <v-list-item-action>
          <v-icon class="white--text">mdi-home</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="white--text">Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import network from '../network'

  export default {
    name: 'Nav',
    computed: {
      is_system () {
        return this.$store.getters.is_system
      }
    },
    methods: {
      logout () {
        console.log('Logout from Nav bar.')
        network.logout()
      },
      home () {
        this.$router.push('/')
      }
    },
    data () {
      return {
        items: [
          { title: 'Home', icon: 'dashboard' },
          { title: 'About', icon: 'question_answer' }
        ],
        right: null
      }
    }
  }
</script>

<style scoped>
</style>
