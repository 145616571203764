<template>
  <div>
    <v-app-bar app color="primary" dark v-if="!inPrint">
      <v-toolbar-title class="display-1">
        <span>{{ companyName }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="subheading">{{ fullName }}</div>
      <v-btn @click="logout" text v-if="loggedIn">
        <span class="mr-2">Logout</span>
      </v-btn>
    </v-app-bar>

    <Nav v-if="!inPrint"></Nav>

    <v-content>
      <router-view v-if="loggedIn"></router-view>
    </v-content>
  </div>
</template>

<script>
import Nav from './Nav'
import network from '../network'

export default {
  name: 'Admin',
  components: {
    Nav
  },

  created() {
    console.log('Created:', this.$options.name)
  },

  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn
    },
    companyName() {
      return this.$store.getters.companyName
    },
    fullName() {
      return this.$store.getters.fullName
    },
    inPrint() {
      return this.$store.getters.inPrint
    }
  },

  methods: {
    logout() {
      console.log('Logout from Admin screen.')
      network.logout()
    },
    data() {
      return {}
    }
  }
}
</script>

<style scoped>
</style>
